import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar
import Footer from "./Footer"; // Import the Footer component

function Reserva() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    page: {
      margin: "0",
      padding: "0",
      minHeight: "100vh",
      backgroundColor: isMobile ? "#ffffff" : "#f5f5f5", // Light gray background for desktop
    },
    desktopSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      minHeight: "100vh",
      paddingTop: "150px", // Extra padding for navbar overlap
      paddingBottom: "100px",
      backgroundImage: "url('/images/vista01.png')", // Replace with your background image
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    textBox: {
      backgroundColor: "rgba(255, 255, 255, 0.9)", // Increased transparency
      color: "#000000", // Black text
      padding: "20px",
      borderRadius: "10px",
      margin: "20px 0",
      maxWidth: "800px",
      textAlign: "center",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow for visual appeal
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
    },
    buttonContainer: {
      marginTop: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "15px 30px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
    mobileImageSection: {
      width: "100%",
      height: "auto",
      marginTop: "60px", // Space below the navbar
    },
    mobileTextBox: {
      backgroundColor: "#ffffff", // White background for mobile
      color: "#000000", // Black text for mobile
      padding: "20px",
      borderRadius: "10px",
      margin: "20px 10px",
      textAlign: "center",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for consistency
    },
    mobileContent: {
      backgroundColor: "#ffffff",
      padding: "30px 20px",
    },
  };

  return (
    <div>
      {/* Navbar */}
      <NavbarComponent /> {/* Use the reusable NavbarComponent */}

      {/* Page Content */}
      <div style={styles.page}>
        {/* Desktop Version */}
        {!isMobile && (
          <div style={styles.desktopSection}>
            <div style={styles.textBox}>
              <h1 style={styles.title}>Reserva Tu Clase</h1>
              <p style={styles.description}>
                Para poder reservar tu clase, es necesario haber pagado tu membresía. Asegúrate de realizar el pago y disfrutar de todos nuestros servicios.
              </p>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.button}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                    e.currentTarget.style.transform = styles.buttonHover.transform;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                  onClick={() => navigate("/memberships")}
                >
                  Ver Membresías
                </button>
              </div>
            </div>
            <div style={styles.textBox}>
              <h2 style={styles.title}>¿Ya Pagaste? Reserva a través de Nuestra App</h2>
              <p style={styles.description}>
                Descarga nuestra aplicación para gestionar tus reservas de forma sencilla y rápida. Reserva tu espacio en las clases con tan solo unos clics.
              </p>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.button}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                    e.currentTarget.style.transform = styles.buttonHover.transform;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                  onClick={() => window.open("https://apps.apple.com/us/app/uptivo/id1124798517", "_blank")}
                >
                  Reserva en nuestra App
                </button>
              </div>
            </div>
            <div style={styles.textBox}>
              <h2 style={styles.title}>¿Problemas con el Acceso?</h2>
              <p style={styles.description}>
                Si ya realizaste tu pago y no puedes acceder a la sección de reservas, contáctanos para ayudarte.
              </p>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.button}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                    e.currentTarget.style.transform = styles.buttonHover.transform;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                  onClick={() => window.open("https://wa.me/74823777", "_blank")}
                >
                  Contáctanos al WhatsApp
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Version */}
        {isMobile && (
          <>
            <div>
              <img
                src="/images/vista01.png"
                alt="Reserva Background"
                style={styles.mobileImageSection}
              />
            </div>
            <div style={styles.mobileContent}>
              <div style={styles.mobileTextBox}>
                <h1 style={styles.title}>Reserva Tu Clase</h1>
                <p style={styles.description}>
                  Para poder reservar tu clase, es necesario haber pagado tu membresía. Asegúrate de realizar el pago y disfrutar de todos nuestros servicios.
                </p>
                <div style={styles.buttonContainer}>
                  <button style={styles.button} onClick={() => navigate("/memberships")}>
                    Ver Membresías
                  </button>
                </div>
              </div>
              <div style={styles.mobileTextBox}>
                <h2 style={styles.title}>¿Ya Pagaste? Reserva a través de Nuestra App</h2>
                <p style={styles.description}>
                  Descarga nuestra aplicación para gestionar tus reservas de forma sencilla y rápida. Reserva tu espacio en las clases con tan solo unos clics.
                </p>
                <div style={styles.buttonContainer}>
                  <button
                    style={styles.button}
                    onClick={() => window.open("https://apps.apple.com/us/app/uptivo/id1124798517", "_blank")}
                  >
                    Reserva en nuestra App
                  </button>
                </div>
              </div>
              <div style={styles.mobileTextBox}>
                <h2 style={styles.title}>¿Problemas con el Acceso?</h2>
                <p style={styles.description}>
                  Si ya realizaste tu pago y no puedes acceder a la sección de reservas, contáctanos para ayudarte.
                </p>
                <div style={styles.buttonContainer}>
                  <button
                    style={styles.button}
                    onClick={() => window.open("https://wa.me/74823777", "_blank")}
                  >
                    Contáctanos al WhatsApp
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Reserva;