import React, { useState, useEffect } from "react";

function Entrenadores() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    entrenadoresSection: {
      padding: "50px",
      backgroundImage: "url('/images/headerbackground.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      color: "#ffffff",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "30px",
      color: "#ffffff",
    },
    coachesContainer: {
      display: "grid",
      gridTemplateColumns: isMobile ? "1fr" : "repeat(2, 1fr)",
      gap: "20px",
    },
    coachCard: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      display: "flex",
      flexDirection: "column", // Always stack vertically
      alignItems: "center",
      textAlign: "center",
    },
    coachImage: {
      width: "100px",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "10px",
    },
    coachName: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "5px",
      color: "#ffffff",
    },
    coachPosition: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#1466C3",
      marginBottom: "10px",
    },
    coachDescription: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#ffffff",
    },
  };

  return (
    <section id="entrenadores" style={styles.entrenadoresSection}>
      <h2 style={styles.title}>Entrenadores</h2>
      <div style={styles.coachesContainer}>
        {/* Coach 1 */}
        <div style={styles.coachCard}>
          <img
            src="/images/coach1.jpg"
            alt="Coach 1"
            style={styles.coachImage}
          />
          <h3 style={styles.coachName}>Marcos Rivera</h3>
          <p style={styles.coachPosition}>Entrenador Jefe</p>
          <p style={styles.coachDescription}>
          “Ex atleta de alto rendimiento con más de 8 años 
          de experiencia como entrenador personal y grupal 
          en entrenamientos funcionales, adaptados para todas las edades.”
          </p>
        </div>
        {/* Coach 2 */}
        <div style={styles.coachCard}>
          <img
            src="/images/coach2.jpg"
            alt="Coach 2"
            style={styles.coachImage}
          />
          <h3 style={styles.coachName}>Alejandra Santamaria</h3>
          <p style={styles.coachPosition}>Entrenadora</p>
          <p style={styles.coachDescription}>
          Con más de 14 años de experiencia en diferentes deportes competitivos,
          graduada de entrenadora personal y gestión de centros fitness en Guatemala.
          </p>
        </div>
        {/* Coach 3 */}
        <div style={styles.coachCard}>
          <img
            src="/images/coach3.jpg"
            alt="Coach 3"
            style={styles.coachImage}
          />
          <h3 style={styles.coachName}>Jimmy Aguilar</h3>
          <p style={styles.coachPosition}>Entrenador</p>
          <p style={styles.coachDescription}>
          Maestro de boxeo funcional con años de experiencia.
          Jimmy se enfoca en mejorar la fuerza, resistencia y coordinación. Dirige clases dinámicas para grupos de todos los niveles,
          adaptando entrenamientos para maximizar resultados.
          </p>
        </div>
        {/* Coach 4 */}
        <div style={styles.coachCard}>
          <img
            src="/images/coach4.jpg"
            alt="Coach 4"
            style={styles.coachImage}
          />
          <h3 style={styles.coachName}>Isaac Rivera</h3>
          <p style={styles.coachPosition}>Entrenador</p>
          <p style={styles.coachDescription}>
          Entrenador con más de 10 años de experiencia,
          es especialista en entrenamiento de fuerza, fitness, entrenamiento funcional y HIIT.
          Ofrece sesiones dinámicas, seguras y efectivas para mejorar la condición física y el rendimiento.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Entrenadores;