import React, { useState, useEffect } from "react";

function Reviews() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    reviewsSection: {
      padding: "50px",
      backgroundColor: "#f8f9fa",
      textAlign: "center",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "30px",
    },
    reviewsContainer: {
      display: "grid",
      gridTemplateColumns: isMobile ? "1fr" : "repeat(2, 1fr)", // One column for mobile, two columns for desktop
      gap: "20px",
    },
    reviewCard: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
    },
    quote: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "10px",
    },
    clientName: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "right",
    },
  };

  return (
    <section id="reviews" style={styles.reviewsSection}>
      <h2 style={styles.title}>Opiniones de Nuestros Clientes</h2>
      <div style={styles.reviewsContainer}>
        {/* Review 1 */}
        <div style={styles.reviewCard}>
          <p style={styles.quote}>
            "FitInstinct ha transformado mi manera de entrenar. La tecnología y
            el apoyo del equipo son increíbles."
          </p>
          <p style={styles.clientName}>- Juan Pérez</p>
        </div>
        {/* Review 2 */}
        <div style={styles.reviewCard}>
          <p style={styles.quote}>
            "Gracias a FitInstinct he logrado alcanzar metas que nunca pensé
            posibles. Su método es único."
          </p>
          <p style={styles.clientName}>- María Gómez</p>
        </div>
        {/* Review 3 */}
        <div style={styles.reviewCard}>
          <p style={styles.quote}>
            "El monitoreo en tiempo real y la gamificación me mantienen motivado
            en cada sesión."
          </p>
          <p style={styles.clientName}>- Carlos Ruiz</p>
        </div>
        {/* Review 4 */}
        <div style={styles.reviewCard}>
          <p style={styles.quote}>
            "El ambiente en FitInstinct es perfecto. Los entrenadores son muy
            profesionales y atentos."
          </p>
          <p style={styles.clientName}>- Laura Fernández</p>
        </div>
      </div>
    </section>
  );
}

export default Reviews;
