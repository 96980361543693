import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar component
import Tecnologia from "./Tecnologia"; // Updated Tecnologia component
import SensorPage from "./SensorPage"; // Existing SensorPage component
import Metodologia from "./Metodologia"; // Existing Metodologia component
import Footer from "./Footer"; // Footer component

function WorkoutPage() {
  const styles = {
    pageContainer: {
      paddingTop: "70px", // Adjusted space for fixed navbar
      backgroundColor: "#ffffff", // Set entire page background to white
    },
    sectionWrapper: {
      margin: "0", // Remove external margins
      padding: "0", // Remove padding
    },
  };

  return (
    <div>
      {/* Navbar */}
      <NavbarComponent /> {/* Use the reusable NavbarComponent */}

      {/* Page Content */}
      <div style={styles.pageContainer}>
        {/* Updated Tecnologia Section */}
        <div style={styles.sectionWrapper}>
          <Tecnologia />
        </div>

        {/* Metodología Section */}
        <div style={styles.sectionWrapper}>
          <Metodologia />
        </div>

        {/* Sensor Section */}
        <div style={styles.sectionWrapper}>
          <SensorPage />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default WorkoutPage;