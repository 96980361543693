import React, { useState, useEffect } from "react";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar
import Footer from "./Footer"; // Import the Footer component

function Contactanos() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    page: {
      margin: "0",
      padding: "0",
      backgroundColor: isMobile ? "#ffffff" : "#262626",
    },
    desktopSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      paddingTop: "120px",
      paddingBottom: "50px",
      backgroundImage: "url('/images/vista04.png')", // Replace with your background image
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    textBox: {
      backgroundColor: "#ffffff", // White background
      color: "#000000", // Black text
      padding: "30px",
      borderRadius: "10px",
      margin: "20px 0",
      maxWidth: "800px",
      textAlign: "center",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Subtle shadow
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
    },
    buttonContainer: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "center",
      gap: "15px",
      flexWrap: "wrap",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "15px 30px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease",
    },
    mobileImageSection: {
      width: "100%",
      height: "auto",
      marginTop: "70px",
    },
    mobileContent: {
      backgroundColor: "#ffffff",
      padding: "30px 20px", // Proper padding to balance spacing
      marginBottom: "0px",
      textAlign: "center",
    },
    mobileText: {
      color: "#000000",
      marginBottom: "20px",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
    },
  };

  return (
    <div>
      {/* Navbar */}
      <NavbarComponent /> {/* Use the reusable NavbarComponent */}

      {/* Page Content */}
      <div style={styles.page}>
        {/* Desktop Version */}
        {!isMobile && (
          <div style={styles.desktopSection}>
            <div style={styles.textBox}>
              <h1 style={styles.title}>Contáctanos</h1>
              <p style={styles.description}>
                Si tienes alguna duda o necesitas ayuda, no dudes en contactarnos a través de WhatsApp o correo electrónico.
              </p>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.button}
                  onClick={() => window.open("https://wa.me/50374823777", "_blank")}
                >
                  Contáctanos por WhatsApp
                </button>
                <button
                  style={styles.button}
                  onClick={() => window.open("mailto:servicioalcliente@fit-instinct.com", "_blank")}
                >
                  Envíanos un Correo
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Version */}
        {isMobile && (
          <>
            <div>
              <img
                src="/images/vista04.png"
                alt="Contáctanos Background"
                style={styles.mobileImageSection}
              />
            </div>
            <div style={styles.mobileContent}>
              <h1 style={styles.title}>Contáctanos</h1>
              <p style={styles.mobileText}>
                Si tienes alguna duda o necesitas ayuda, no dudes en contactarnos a través de WhatsApp o correo electrónico.
              </p>
              <div style={styles.buttonContainer}>
                <button
                  style={styles.button}
                  onClick={() => window.open("https://wa.me/50374823777", "_blank")}
                >
                  Contáctanos por WhatsApp
                </button>
                <button
                  style={styles.button}
                  onClick={() => window.open("mailto:servicioalcliente@fit-instinct.com", "_blank")}
                >
                  Envíanos un Correo
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Contactanos;