import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SpecialOffer from "./SpecialOffer";

function NavbarComponent({ showSpecialOffer = true }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    navbar: {
      backgroundColor: "#262626",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 1rem",
    },
    brandToggleContainer: {
      display: "flex",
      alignItems: "center",
    },
    brand: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#FFFFFF",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginLeft: isMobile ? "10px" : "0",
      transition: "transform 0.3s ease-in-out",
    },
    brandHover: {
      transform: "scale(1.1)", // Slight enlargement on hover
    },
    toggle: {
      border: "2px solid #FFFFFF",
      color: "#FFFFFF",
      marginRight: isMobile ? "10px" : "0",
    },
    navLink: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      color: "#FFFFFF",
      marginRight: "15px",
      textDecoration: "none",
      cursor: "pointer",
      transition: "transform 0.3s ease-in-out, font-size 0.3s ease-in-out",
    },
    navLinkHover: {
      transform: "scale(1.1)", // Slight enlargement
      fontSize: "1.1rem", // Slightly larger text size
    },
    claseGratisButton: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      border: "none",
      padding: "0.5rem 1rem",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      marginLeft: "auto", // Push button to the far right
    },
    claseGratisButtonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
  };

  return (
    <>
      {/* Navbar */}
      <div style={styles.navbar}>
        <Navbar expand="lg" style={{ width: "100%" }}>
          <div style={styles.brandToggleContainer}>
            {/* Toggle box aligned to the left */}
            <Navbar.Toggle
              aria-controls="navbar-nav"
              style={styles.toggle}
              children={
                <>
                  <span
                    style={{
                      display: "block",
                      height: "2px",
                      width: "20px",
                      backgroundColor: "#FFFFFF",
                      margin: "4px 0",
                    }}
                  ></span>
                  <span
                    style={{
                      display: "block",
                      height: "2px",
                      width: "20px",
                      backgroundColor: "#FFFFFF",
                      margin: "4px 0",
                    }}
                  ></span>
                  <span
                    style={{
                      display: "block",
                      height: "2px",
                      width: "20px",
                      backgroundColor: "#FFFFFF",
                      margin: "4px 0",
                    }}
                  ></span>
                </>
              }
            />

            {/* Logo */}
            <Navbar.Brand
              onClick={() => navigate("/")}
              style={styles.brand}
              onMouseOver={(e) =>
                !isMobile &&
                (e.currentTarget.style.transform =
                  styles.brandHover.transform)
              }
              onMouseOut={(e) =>
                !isMobile &&
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <img
                src={
                  isMobile
                    ? "/images/logos/logo-2row-white.png" // Mobile logo
                    : "/images/logos/logo-1row-white.png" // Default logo
                }
                alt="FitInstinct Logo"
                style={{ height: "40px", cursor: "pointer" }}
              />
            </Navbar.Brand>
          </div>

          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => navigate("/")}
                style={styles.navLink}
                onMouseOver={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform =
                      styles.navLinkHover.transform;
                    e.currentTarget.style.fontSize =
                      styles.navLinkHover.fontSize;
                  }
                }}
                onMouseOut={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.fontSize = styles.navLink.fontSize;
                  }
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/memberships")}
                style={styles.navLink}
                onMouseOver={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform =
                      styles.navLinkHover.transform;
                    e.currentTarget.style.fontSize =
                      styles.navLinkHover.fontSize;
                  }
                }}
                onMouseOut={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.fontSize = styles.navLink.fontSize;
                  }
                }}
              >
                Membresías
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/reserva")}
                style={styles.navLink}
                onMouseOver={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform =
                      styles.navLinkHover.transform;
                    e.currentTarget.style.fontSize =
                      styles.navLinkHover.fontSize;
                  }
                }}
                onMouseOut={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.fontSize = styles.navLink.fontSize;
                  }
                }}
              >
                Reserva
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/contactanos")}
                style={styles.navLink}
                onMouseOver={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform =
                      styles.navLinkHover.transform;
                    e.currentTarget.style.fontSize =
                      styles.navLinkHover.fontSize;
                  }
                }}
                onMouseOut={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.fontSize = styles.navLink.fontSize;
                  }
                }}
              >
                Contáctanos
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* Clase Gratis Button */}
          <Button
            style={styles.claseGratisButton}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                styles.claseGratisButtonHover.backgroundColor;
              e.currentTarget.style.transform =
                styles.claseGratisButtonHover.transform;
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                styles.claseGratisButton.backgroundColor;
              e.currentTarget.style.transform = "scale(1)";
            }}
            onClick={() => navigate("/register")}
          >
            Clase Gratis
          </Button>
        </Navbar>
      </div>

      {/* Special Offer Section */}
      {showSpecialOffer && <SpecialOffer />}
    </>
  );
}

export default NavbarComponent;