import React, { useState, useEffect } from "react";

function About() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    aboutSection: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row", // Stack vertically for mobile
      justifyContent: isMobile ? "center" : "flex-start", // Adjust alignment
      alignItems: "center",
      padding: isMobile ? "0" : "40px 20px", // Remove padding for mobile
      backgroundImage: isMobile ? "none" : "url('/images/lobbybackground.jpeg')", // Background image only for desktop
      backgroundSize: "cover", // Ensure the image covers the section
      backgroundPosition: "center", // Center the background image
      backgroundRepeat: "no-repeat",
      minHeight: isMobile ? "auto" : "90vh", // Maintain height
      position: "relative",
      backgroundColor: isMobile ? "#f8f9fa" : "transparent", // Light background for smaller screens
    },
    contentBox: {
      backgroundColor: isMobile ? "transparent" : "#ffffff", // Transparent for mobile, white for desktop
      color: "#262626", // Black text
      padding: isMobile ? "20px 20px" : "20px", // Adjust padding
      borderRadius: isMobile ? "0" : "10px", // Rounded corners for desktop
      boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow only for desktop
      maxWidth: "600px",
      width: isMobile ? "90%" : "60%", // Full width for small screens
      textAlign: "center",
      margin: isMobile ? "0 auto" : "0 20px 0 auto", // Center for mobile
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "10px", // Reduce bottom margin
      color: "#262626", // Ensure title is black
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#262626", // Ensure content text is black
    },
    imageSection: {
      display: isMobile ? "block" : "none", // Show image only on mobile
      width: "100%", // Full width
      height: "auto", // Maintain aspect ratio
      margin: "0", // Remove margins
    },
    image: {
      width: "100%", // Full width for responsive layout
      height: "auto", // Maintain aspect ratio
      display: "block",
    },
  };

  return (
    <section id="about" style={styles.aboutSection}>
      {/* Image Section for Small Screens */}
      {isMobile && (
        <div style={styles.imageSection}>
          <img
            src="/images/lobbybackground.jpeg" // Path to your image
            alt="Lobby Background"
            style={styles.image}
          />
        </div>
      )}

      {/* Text Section */}
      <div style={styles.contentBox}>
        <h2 style={styles.title}>Sobre Nosotros</h2>
        <p style={styles.content}>
        FitInstinct fue fundado por Natan y Marcos,
        dos hermanos apasionados por una vida saludable que
        combinaron su experiencia en análisis de datos, personal training y
        deporte de alto rendimiento para crear una solución tecnológica única.
        Nuestros programas, software e instalaciones están diseñados
        para optimizar tu condición física, salud, perder peso o mantenerte activo, siempre con el acompañamiento
        de entrenadores expertos que te guiarán hacia tus metas.
        </p>
      </div>
    </section>
  );
}

export default About;