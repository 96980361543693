import React from "react";

function SpecialOfferExplanation() {
  const styles = {
    offerSection: {
      minHeight: "400px", // Increased height for better spacing
      padding: "60px 20px", // Balanced padding for top and bottom
      marginTop: "60px", // Ensure enough space below the navbar
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "15px", // Slightly reduced for better spacing
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.2rem",
      fontWeight: "500",
      lineHeight: "1.5",
      maxWidth: "800px",
      margin: "0 auto",
      marginBottom: "20px", // Consistent spacing below text
    },
    detailsList: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      lineHeight: "1.5",
      maxWidth: "800px",
      margin: "20px auto",
      padding: "0",
      listStyleType: "disc",
      textAlign: "left",
      color: "#FFFFFF",
    },
    price: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#FFFFFF",
      marginTop: "20px",
    },
    button: {
      marginTop: "20px",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#FFFFFF",
      color: "#1466C3",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#F8F8F8",
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
  };

  return (
    <div style={styles.offerSection}>
      <h2 style={styles.title}>Oferta Especial</h2>
      <p style={styles.description}>
        ¡Inscríbete por un año en la membresía <strong>Elite</strong> y obtén{" "}
        <strong>10% de descuento</strong> además de tu sensor gratis!
      </p>
      <ul style={styles.detailsList}>
        <li>Acceso completo a todas las clases grupales.</li>
        <li>Válido por 4 semanas.</li>
        <li>Pago recurrente.</li>
        <li>Acceso a nuestra app.</li>
        <li>Acceso a datos biométricos.</li>
      </ul>
      <p style={styles.price}>Solo $135 cada 4 semanas</p>
      <button
        style={styles.button}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => (window.location.href = "/memberships")}
      >
        Comprar
      </button>
    </div>
  );
}

export default SpecialOfferExplanation;