import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar
import Footer from "./Footer"; // Import the Footer component

function Register() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    dui: "",
    email: "",
    cellphone: "",
  });

  const [message, setMessage] = useState("");

  const styles = {
    backgroundSection: {
      paddingTop: "100px",
      paddingBottom: "50px",
      backgroundImage: "url('/images/headerbackground.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "calc(100vh - 150px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: "10px",
      padding: "30px",
      maxWidth: "400px",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "500",
      fontSize: "1rem",
      backgroundColor: "#1466C3",
      borderColor: "#1466C3",
      textTransform: "uppercase",
      letterSpacing: "2.5px",
      color: "#ffffff",
      border: "none",
      padding: "0.75rem 1.5rem",
      borderRadius: "5px",
      cursor: "pointer",
      marginTop: "20px",
      width: "100%",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
    message: {
      color: "#333",
      fontFamily: "Montserrat, sans-serif",
      marginTop: "10px",
    },
    footerSection: {
      backgroundColor: "#262626",
      padding: "20px 0",
      width: "100%",
      textAlign: "center",
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const API_URL = process.env.REACT_APP_API_URL || "https://www.fit-instinct.com/api";;
  console.log("API URL:", API_URL);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      // Log the response details
      console.log("Response Status:", response.status);
      console.log("Response Headers:", response.headers);
  
      const result = await response.json();
      console.log("Response Body:", result);
  
      if (response.ok) {
        setMessage("¡Recibirás más información en tu correo para tu clase gratis!");
        setFormData({
          name: "",
          lastname: "",
          dui: "",
          email: "",
          cellphone: "",
        });
      } else {
        const errorMessage = result.errors
          ? result.errors.join(", ")
          : `Error: ${response.status} - ${result.message || "Ocurrió un error"}`;
        setMessage(errorMessage);
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setMessage("Error connecting to the server.");
    }
  };

  return (
    <div>
      <NavbarComponent />
      <div style={styles.backgroundSection}>
        <div style={styles.formContainer}>
          <h1 style={styles.title}>Registrate para tu Clase Gratis</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              value={formData.name}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="text"
              name="lastname"
              placeholder="Apellido"
              value={formData.lastname}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="text"
              name="dui"
              placeholder="DUI"
              value={formData.dui}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="tel"
              name="cellphone"
              placeholder="Teléfono"
              value={formData.cellphone}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <button
              type="submit"
              style={styles.button}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor =
                  styles.buttonHover.backgroundColor;
                e.currentTarget.style.transform = styles.buttonHover.transform;
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Enviar
            </button>
          </form>
          {message && <p style={styles.message}>{message}</p>}
        </div>
      </div>
      <div style={styles.footerSection}>
        <Footer />
      </div>
    </div>
  );
}

export default Register;