import React from "react";
import { useNavigate } from "react-router-dom";

function Workout() {
  const navigate = useNavigate(); // Hook for navigation

  const styles = {
    tecnologiaSection: {
      display: "flex",
      flexDirection: "row", // Side-by-side layout for larger screens
      justifyContent: "space-between",
      alignItems: "center",
      padding: "50px",
      backgroundImage: "url('/images/techbackground.jpg')", // Replace with your background image path
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "60vh",
      position: "relative",
    },
    contentBox: {
      backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
      padding: "30px",
      borderRadius: "10px",
      color: "#262626",
      maxWidth: "500px",
      textAlign: "left",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold", // Medium weight
      textTransform: "uppercase", // Uppercase text
      backgroundColor: "#1466C3", // Primary blue from the brand guidelines
      borderColor: "#1466C3",
      padding: "0.75rem 1.5rem",
      color: "#ffffff", // White text
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Slightly darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
    backgroundImage: {
      flex: "1", // Allow the image to take available space
      minHeight: "60vh", // Maintain minimum height
    },
    responsiveContainer: {
      flexDirection: "column", // Stack items vertically on smaller screens
    },
  };

  return (
    <section
      id="workout"
      style={{
        ...styles.tecnologiaSection,
        ...(window.innerWidth < 768 ? styles.responsiveContainer : {}),
      }}
    >
      {/* Text Section */}
      <div style={styles.contentBox}>
        <h2 style={styles.title}>Nuestros Entrenamientos</h2>
        <p style={styles.content}>
          En FitInstinct, integramos tecnología avanzada en todos nuestros
          entrenamientos para garantizar que obtengas resultados efectivos y
          medibles. Descubre cómo transformamos tu fitness.
        </p>
        <button
          style={styles.button}
          onClick={() => navigate("/workout")} // Navigate to WorkoutPage
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
            e.currentTarget.style.transform = styles.buttonHover.transform;
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          Más Información
        </button>
      </div>

      {/* Background Image */}
      <div
        style={{
          ...styles.backgroundImage,
          ...(window.innerWidth < 768 ? { order: -1 } : {}), // Ensure image is at the top on smaller screens
        }}
      ></div>
    </section>
  );
}

export default Workout;