import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Tecnologia from "./components/Tecnologia";
import Metodologia from "./components/Metodologia";
import About from "./components/About";
import Membresias from "./components/Membresias";
import Entrenadores from "./components/Entrenadores";
import Reviews from "./components/Reviews";
import Footer from "./components/Footer";
import NuestraApp from "./components/NuestraApp";
import EntrenamientoEspecializado from "./components/EntrenamientoEspecializado";
import Register from "./components/Register";
import Reserva from "./components/Reserva";
import MembershipsPage from "./components/MembershipPage";
import Contactanos from "./components/Contactanos";
import Workout from "./components/Workout";
import WorkoutPage from "./components/WorkoutPage";
import InformacionEspecializado from "./components/InformacionEspecializado";
import Sistemas from "./components/Sistemas";
import NavbarComponent from "./components/NavbarComponent";

function Home() {
  return (
    <div>
      <NavbarComponent/>
      <Header />
      <About />
      <Workout/>
      <Membresias />
      <EntrenamientoEspecializado />
      <NuestraApp />
      <Entrenadores />
      <Reviews />
      <Footer />
    </div>
  );
}


function App() {
  return (
    <Router>
     <ScrollToTop /> {/* Ensure this is included */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reserva" element={<Reserva />} />
        <Route path="/memberships" element={<MembershipsPage />} />
        <Route path="/contactanos" element={<Contactanos />} />
        <Route path="/workout" element={<WorkoutPage />} />
        <Route path="/informacion-especializado" element={<InformacionEspecializado />} />
        <Route path="/sistemas" element={<Sistemas />} />
      </Routes>
    </Router>
  );
}

export default App;
