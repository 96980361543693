import React, { useState, useEffect } from "react";

function Membresias() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    membershipsSection: {
      padding: "100px 20px 50px 20px", // Add more top padding for space from the navbar
      backgroundImage: "url('/images/bluegirl.jpg')",
      backgroundSize: "cover",
      backgroundPosition: isMobile ? "30% center" : "center",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      color: "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      minHeight: "100vh",
      width: "100%",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "30px",
    },
    membershipsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      maxWidth: "600px",
      width: "100%",
    },
    membershipCard: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    membershipTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#ffffff",
    },
    bulletList: {
      textAlign: "left", // Align text to the left
      margin: "10px 0",
      padding: "0",
      listStyleType: "disc",
      paddingLeft: "20px", // Add padding for indentation
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    price: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
    e.currentTarget.style.transform = styles.buttonHover.transform;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
    e.currentTarget.style.transform = "scale(1)";
  };

  return (
    <section id="memberships" style={styles.membershipsSection}>
      <h2 style={styles.title}>Membresías</h2>
      <div style={styles.membershipsContainer}>
        {/* One Class */}
        <div style={styles.membershipCard}>
          <h3 style={styles.membershipTitle}>One Class</h3>
          <ul style={styles.bulletList}>
            <li>1 clase válida por 4 semanas.</li>
            <li>Acceso a nuestra app durante 4 semanas.</li>
            <li>Resumen de rendimiento enviado a tu correo despues de tu clase.</li>
          </ul>
          <p style={styles.price}>$12</p>
          <button
            style={styles.button}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => alert("Comprar Una Clase")}
          >
            Comprar
          </button>
        </div>
        {/* 8 Clases */}
        <div style={styles.membershipCard}>
          <h3 style={styles.membershipTitle}>Casual</h3>
          <ul style={styles.bulletList}>
            <li>8 clases válidas por un mes.</li>
            <li>Acceso a nuestra app.</li>
            <li>Resumen de rendimiento enviado a tu correo despues de cada clase.</li>
            <li>Registro historico de rendimiento.</li>
            <li>Participacion en Fitinstinct challenges.</li>
            <li>Pago recurrente.</li>
          </ul>
          <p style={styles.price}>$85 al mes</p>
          <button
            style={styles.button}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => alert("Comprar 8 Clases")}
          >
            Comprar
          </button>
        </div>
        {/* 12 Clases */}
        <div style={styles.membershipCard}>
          <h3 style={styles.membershipTitle}>Athlete</h3>
          <ul style={styles.bulletList}>
            <li>12 clases válidas cada mes.</li>
            <li>Acceso a nuestra app.</li>
            <li>Resumen de rendimiento enviado a tu correo despues de cada clase.</li>
            <li>Registro historico de rendimiento.</li>
            <li>Participacion en Fitinstinct challenges.</li>
            <li>Pago recurrente.</li>
          </ul>
          <p style={styles.price}>$120 al mes</p>
          <button
            style={styles.button}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => alert("Comprar 12 Clases")}
          >
            Comprar
          </button>
        </div>
        {/* Membresía Ilimitada */}
        <div style={styles.membershipCard}>
          <h3 style={styles.membershipTitle}>Elite</h3>
          <ul style={styles.bulletList}>
            <li>Acceso completo a todas las clases grupales.</li>
            <li>Válido en cualquier horario</li>
            <li>Acceso a nuestra app.</li>
            <li>Resumen de rendimiento enviado a tu correo despues de cada clase.</li>
            <li>Registro historico de rendimiento.</li>
            <li>Acceso a seguimiento de datos biométricos.</li>
            <li>Participacion en Fitinstinct challenges.</li>
            <li>Pago recurrente.</li>
          </ul>
          <p style={styles.price}>$155 al mes</p>
          <button
            style={styles.button}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => alert("Comprar Membresía Ilimitada")}
          >
            Comprar
          </button>
        </div>
      </div>
    </section>
  );
}

export default Membresias;