import React from "react";
import NavbarComponent from "./NavbarComponent";
import Membresias from "./Membresias";
import SensorPage from "./SensorPage";
import Footer from "./Footer";
import SpecialOfferExplanation from "./SpecialOfferExplanation";

function MembershipsPage() {
  const styles = {
    pageContainer: {
      margin: "0",
      padding: "0",
    },
    section: {
      width: "100%",
      minHeight: "100vh",
      margin: "0",
      padding: "0",
      backgroundColor: "#262626",
    },
    firstSection: {
      margin: "0", // No gap at the top
      padding: "0", // No padding at the top
    },
    secondSection: {
      margin: "0",
      padding: "0",
    },
  };

  return (
    <div style={styles.pageContainer}>
      {/* Navbar without Special Offer */}
      <NavbarComponent showSpecialOffer={false} />

      {/* Special Offer Explanation */}
      <SpecialOfferExplanation />

      {/* Page Content */}
      <div style={{ ...styles.section, ...styles.firstSection }}>
        <Membresias />
      </div>
      <div style={{ ...styles.section, ...styles.secondSection }}>
        <SensorPage />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MembershipsPage;