import React, { useState, useEffect } from "react";

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    footer: {
      backgroundColor: "#262626",
      color: "#ffffff",
      padding: "30px",
      textAlign: "center",
    },
    container: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: isMobile ? "center" : "space-between",
      alignItems: isMobile ? "center" : "flex-start",
      flexWrap: "wrap",
      maxWidth: "1200px",
      margin: "0 auto",
      gap: isMobile ? "20px" : "0",
    },
    section: {
      flex: "1",
      margin: "10px",
      textAlign: isMobile ? "center" : "left",
    },
    navLinks: {
      listStyleType: "none",
      padding: "0",
    },
    navItem: {
      margin: "5px 0",
    },
    navLink: {
      color: "#ffffff",
      textDecoration: "none",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.9rem",
    },
    navLinkHover: {
      textDecoration: "underline",
    },
    socialIcons: {
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      gap: "15px",
      marginTop: "10px",
    },
    icon: {
      width: "24px",
      height: "24px",
    },
    copyright: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.8rem",
      marginTop: "20px",
    },
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        {/* Navigation Links */}
        <div style={styles.section}>
          <h4 style={{ fontFamily: "Gendy, sans-serif", fontSize: "1.2rem" }}>
            Navegación
          </h4>
          <ul style={styles.navLinks}>
            <li style={styles.navItem}>
              <a href="/" style={styles.navLink}>
                Inicio
              </a>
            </li>
            <li style={styles.navItem}>
              <a href="/memberships" style={styles.navLink}>
                Membresías
              </a>
            </li>
            <li style={styles.navItem}>
              <a href="/workout" style={styles.navLink}>
                Métodos de Entrenamiento
              </a>
            </li>
            <li style={styles.navItem}>
              <a href="/reserva" style={styles.navLink}>
                Reserva
              </a>
            </li>
            <li style={styles.navItem}>
              <a href="/sistemas" style={styles.navLink}>
                Sistemas
              </a>
            </li>
            <li style={styles.navItem}>
              <a href="/contactanos" style={styles.navLink}>
                Contáctanos
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div style={styles.section}>
          <h4 style={{ fontFamily: "Gendy, sans-serif", fontSize: "1.2rem" }}>
            Contacto
          </h4>
          <p style={{ fontFamily: "Montserrat, sans-serif", fontSize: "0.9rem" }}>
            Dirección: C.C. Distrito Las Piletas, Km 12 1/2 Carretera 
            al Puerto de La Libertad, Santa Tecla, Local 1 y 2.<br />
            Email: servicioalcliente@fitinstinct.com<br />
            Teléfono: +503 7482-3777
          </p>
        </div>

        {/* Social Media */}
        <div style={styles.section}>
          <h4 style={{ fontFamily: "Gendy, sans-serif", fontSize: "1.2rem" }}>
            Síguenos
          </h4>
          <div style={styles.socialIcons}>
            <a href="https://www.facebook.com/people/FitInstinct/61567781076568/" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/Facebook.png"
                alt="Facebook"
                style={styles.icon}
              />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/X.png"
                alt="Twitter"
                style={styles.icon}
              />
            </a>
            <a href="https://www.instagram.com/fitinstinct_sv/" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/insta.png"
                alt="Instagram"
                style={styles.icon}
              />
            </a>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div style={styles.copyright}>
        © {new Date().getFullYear()} FitInstinct. Todos los derechos reservados.
      </div>
    </footer>
  );
}

export default Footer;