import React, { useState, useEffect } from "react";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar component
import Footer from "./Footer"; // Import the Footer component

function Sistemas() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    topSection: {
      marginTop: "30px", // Adds margin below the navbar
      padding: "100px 20px 50px",
      backgroundColor: "#ffffff",
      textAlign: "center",
      color: "#000000",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "15px 30px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      marginTop: "20px",
      transition: "all 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
    sectionWrapper: {
      padding: "30px",
      backgroundColor: "#ffffff",
      color: "#262626",
    },
    methodWrapper: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "50px",
      gap: "20px",
    },
    methodImage: {
      flex: "1",
      maxWidth: "100%", // Ensure it doesn't exceed the screen width
      width: isMobile ? "90%" : "50%", // Adjust width for mobile and desktop
      height: "auto",
      borderRadius: "10px",
      order: isMobile ? "1" : "0",
    },
    methodText: {
      flex: "1",
      fontFamily: "Montserrat, sans-serif",
      textAlign: isMobile ? "center" : "left",
      padding: "20px",
      order: isMobile ? "2" : "0",
    },
    reverseOrderImage: {
      flex: "1",
      maxWidth: "100%", // Ensure it doesn't exceed the screen width
      width: isMobile ? "90%" : "50%", // Adjust width for mobile and desktop
      height: "auto",
      borderRadius: "10px",
      order: isMobile ? "1" : "1",
    },
    reverseOrderText: {
      flex: "1",
      fontFamily: "Montserrat, sans-serif",
      textAlign: isMobile ? "center" : "left",
      padding: "20px",
      order: isMobile ? "2" : "-1",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
    },
    poweredBy: {
      textAlign: "center",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#262626",
      marginTop: "20px",
      padding: "10px",
    },
  };

  return (
    <div>
      {/* Navbar */}
      <NavbarComponent />

      {/* Top Section */}
      <div style={styles.topSection}>
        <h1 style={styles.title}>Únete Hoy y Ten Acceso a La App</h1>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img
            src="/images/pantallas.png"
            alt="App Preview"
            style={{
              width: "100%",
              maxWidth: isMobile ? "300px" : "600px", // Larger image for desktop
              margin: "20px auto",
            }}
          />
          <button
            style={styles.button}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
              e.currentTarget.style.transform = styles.buttonHover.transform;
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
              e.currentTarget.style.transform = "scale(1)";
            }}
            onClick={() => (window.location.href = "/memberships")}
          >
            Reserva Hoy
          </button>
        </div>
      </div>

      {/* Content Sections */}
      <div style={styles.methodWrapper}>
        <div style={styles.reverseOrderText}>
          <h2 style={styles.title}>Organiza tu Entrenamiento</h2>
          <p style={styles.description}>
            Reserva tus clases en segundos, accede a tu calendario personalizado y
            recibe notificaciones en tiempo real sobre cambios o nuevas aperturas.
            Todo desde la palma de tu mano, disponible las 24 horas para adaptarse a tu estilo de vida.
            ¡Descárgala hoy y lleva tu experiencia fitness al siguiente nivel!
          </p>
        </div>
        <img
          src="/images/agendar.png"
          alt="Team Training"
          style={styles.reverseOrderImage}
        />
      </div>
      <div style={styles.sectionWrapper}>
        <div style={styles.methodWrapper}>
          <img
            src="/images/realtimetrack.png"
            alt="Real-Time Tracking"
            style={styles.methodImage}
          />
          <div style={styles.methodText}>
            <h2 style={styles.title}>Seguimiento en Tiempo Real</h2>
            <p style={styles.description}>
              Monitorea zonas de frecuencia cardíaca, intensidad y calorías
              quemadas en cada entrenamiento con tecnología avanzada.
            </p>
          </div>
        </div>

        <div style={styles.methodWrapper}>
          <div style={styles.reverseOrderText}>
            <h2 style={styles.title}>Datos Biométricos</h2>
            <p style={styles.description}>
              Accede a datos precisos sobre tu rendimiento físico para
              personalizar cada sesión y maximizar tus resultados.
            </p>
          </div>
          <img
            src="/images/Biometricos.png"
            alt="Biometric Insights"
            style={styles.reverseOrderImage}
          />
        </div>

        <div style={styles.methodWrapper}>
          <img
            src="/images/seguimiento.png"
            alt="Progress Tracking"
            style={styles.methodImage}
          />
          <div style={styles.methodText}>
            <h2 style={styles.title}>Seguimiento de Progreso</h2>
            <p style={styles.description}>
              Analiza tu progreso con reportes detallados que te ayudan a
              mejorar constantemente y alcanzar tus objetivos.
            </p>
          </div>
        </div>
      </div>
      {/* Powered by Uptivo */}
      <div style={styles.poweredBy}>
        <p>Powered by Uptivo</p>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Sistemas;