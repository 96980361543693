import React, { useEffect, useState } from "react";

function Metodologia() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    metodologiaSection: {
      padding: "30px",
      backgroundColor: "#ffffff",
      color: "#262626",
    },
    generalDescription: {
      textAlign: "center",
      margin: "10px auto 30px",
      display: "flex",
      justifyContent: "center",
    },
    generalDescriptionBox: {
      backgroundColor: "#111111",
      color: "#ffffff",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      maxWidth: "800px",
      marginBottom: "60px",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    methodWrapper: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "50px",
      gap: "20px",
    },
    methodImage: {
      flex: "1",
      width: "100%",
      maxWidth: isMobile ? "100%" : "50%",
      height: "auto",
      borderRadius: "10px",
      order: isMobile ? "1" : "0",
    },
    methodText: {
      flex: "1",
      fontFamily: "Montserrat, sans-serif",
      textAlign: isMobile ? "center" : "left",
      padding: isMobile ? "20px 0" : "20px",
      order: isMobile ? "2" : "0",
    },
    reverseOrderImage: {
      flex: "1",
      width: "100%",
      maxWidth: isMobile ? "100%" : "50%",
      height: "auto",
      borderRadius: "10px",
      order: isMobile ? "1" : "1",
    },
    reverseOrderText: {
      flex: "1",
      fontFamily: "Montserrat, sans-serif",
      textAlign: isMobile ? "center" : "left",
      padding: isMobile ? "20px 0" : "20px",
      order: isMobile ? "2" : "-1",
    },
    methodTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    methodDescription: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  };

  return (
    <section id="metodologia" style={styles.metodologiaSection}>
      {/* General Description */}
      <div style={styles.generalDescription}>
        <div style={styles.generalDescriptionBox}>
          <h2 style={styles.title}>Metodología</h2>
          <p style={styles.content}>
            Nuestro método de entrenamiento está diseñado para adaptarse a las
            necesidades de cada persona, independientemente de su edad, nivel de
            experiencia o metas específicas. Ofrecemos diferentes tipos de
            entrenamiento que garantizan resultados óptimos y una experiencia
            dinámica y motivadora.
          </p>
        </div>
      </div>

      {/* Training Methods */}
      <div style={styles.methodWrapper}>
        <img
          src="/images/battlerope.jpg"
          alt="Dynamic Training"
          style={styles.methodImage}
        />
        <div style={styles.methodText}>
          <h3 style={styles.methodTitle}>DYNAMIC</h3>
          <p style={styles.methodDescription}>
            Entrenamiento basado en movimientos dinámicos diseñados para
            mejorar agilidad, resistencia y coordinación.
          </p>
        </div>
      </div>

      <div style={styles.methodWrapper}>
        <div style={styles.reverseOrderText}>
          <h3 style={styles.methodTitle}>LIFT</h3>
          <p style={styles.methodDescription}>
            Entrenamiento con pesas enfocado en desarrollar fuerza muscular y
            mejorar la postura corporal.
          </p>
        </div>
        <img
          src="/images/dumbbells.jpg"
          alt="Lift Training"
          style={styles.reverseOrderImage}
        />
      </div>

      <div style={styles.methodWrapper}>
        <img
          src="/images/calisthenics.jpg"
          alt="Calisthenics Training"
          style={styles.methodImage}
        />
        <div style={styles.methodText}>
          <h3 style={styles.methodTitle}>CALISTHENICS</h3>
          <p style={styles.methodDescription}>
            Ejercicios con peso corporal que ayudan a mejorar la flexibilidad,
            el equilibrio y la fuerza funcional.
          </p>
        </div>
      </div>

      <div style={styles.methodWrapper}>
        <div style={styles.reverseOrderText}>
          <h3 style={styles.methodTitle}>BOX</h3>
          <p style={styles.methodDescription}>
            Entrenamiento de boxeo para mejorar la resistencia cardiovascular y
            liberar estrés mientras aprendes habilidades técnicas.
          </p>
        </div>
        <img
          src="/images/boxingbag.jpg"
          alt="Box Training"
          style={styles.reverseOrderImage}
        />
      </div>
    </section>
  );
}

export default Metodologia;